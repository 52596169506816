import { mapGetters } from "vuex";

export default {
  computed: {
    ...mapGetters({
      routes: "getRoutes",
      loggedUser: "user/getUser"
    })
  },

  methods: {
    userHasRoutePermission(routeName) {
      return this.routes?.some(
        route =>
          route.name === routeName &&
          route?.meta?.permissions.includes(this.loggedUser.role.id)
      );
    },

    userHasProfile(profileList) {
      return profileList.includes(this.loggedUser.role.id);
    }
  }
};
