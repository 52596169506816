<template>
  <v-container>
    <title-header :title="$route.meta.title" />
    <v-tabs
      hide-slider
      active-class="tab-active"
      background-color="transparent"
      v-model="activeTab"
    >
      <v-tab class="text-capitalize" v-for="item in items" :key="item">
        {{ item }}
      </v-tab>
      <v-tabs-items v-model="activeTab">
        <v-tab-item>
          <list-grantee v-if="activeTab === TABS.grantee" />
        </v-tab-item>
        <v-tab-item>
          <list-recycler v-if="activeTab === TABS.residue"></list-recycler>
        </v-tab-item>
      </v-tabs-items>
    </v-tabs>
  </v-container>
</template>

<script>
  import ListGrantee from "@/modules/stock/destination/views/components/ListGrantee";
  import ListRecycler from "@/modules/stock/destination/views/components/ListRecycler";
  import TitleHeader from "@/modules/core/views/components/TitleHeader";

  export default {
    name: "Destinations",
    components: {
      ListGrantee,
      ListRecycler,
      TitleHeader
    },
    data: () => ({
      activeTab: null,
      TABS: {
        grantee: 0,
        residue: 1
      },
      items: ["Recondicionados", "Resíduos"]
    }),
    computed: {},
    mounted() {
      this.activeTab = this.TABS.grantee;
    },
    methods: {}
  };
</script>

<style lang="scss" scoped></style>
