<template>
  <v-card class="pt-7">
    <div class="table-page d-flex flex-column justify-space-between">
      <div>
        <template v-if="showFilters">
          <div class="row px-5 d-flex justify-end">
            <v-col cols="9" sm="8" md="3">
              <v-text-field
                @input="setFilterDebounced('search', $event)"
                solo
                flat
                full-width
                color="secondary-color-1"
                background-color="white-1"
                placeholder="Buscar (Ex: nome / empresa)"
                prepend-inner-icon="mdi-magnify"
              />
            </v-col>
            <v-col cols="3" sm="4" md="2">
              <report-options
                fileName="Destinações"
                :header="headerReport"
                :body="recyclers"
              />
            </v-col>
          </div>
          <v-divider />
        </template>
        <template v-if="!recyclers.length">
          <div class="pt-16">
            <data-not-found
              v-if="!filterPristine"
              title="Donatário não encontrado."
              :imgSrc="require('@/assets/user.svg')"
            />
            <data-not-found
              v-if="filterPristine"
              btnLabel="Cadastrar destinação"
              :title="messageEmptyState"
              :imgSrc="require('@/assets/partner.png')"
              :btnAction="goToAddDonate"
              :canSeeAction="canRegisterDonate"
            />
          </div>
        </template>
        <template v-if="recyclers.length">
          <v-data-table
            :options.sync="dataTableOptions"
            :headers="headers"
            :items="recyclers"
            :server-items-length="tableInfo.totalItems"
            hide-default-footer
          >
            <template v-slot:item.id="{ item }">
              <recycler-menu-item :id="item.id" />
            </template>
          </v-data-table>
        </template>
      </div>
      <div v-if="recyclers.length">
        <pagination
          :currentPage="tableInfo.currentPage"
          :totalPages="tableInfo.totalPages"
          :itemsPerPage="tableInfo.itemsPerPage"
          @currentPageChanged="handleCurrentPageChange($event)"
          @itemsPerPageChanged="handleItemsPerPageChange($event)"
        />
      </div>
    </div>
  </v-card>
</template>

<script>
  import { mapActions } from "vuex";

  import DataNotFound from "@/modules/core/views/components/DataNotFound";
  import Pagination from "@/modules/core/views/components/Pagination";
  import RecyclerMenuItem from "@/modules/stock/destination/views/components/RecyclerMenuItem";
  import ReportOptions from "@/modules/core/views/components/ReportOptions";

  import MasksMixin from "@/modules/core/mixins/masksMixin";
  import PermissionMixin from "@/modules/core/mixins/permissionMixin";
  import TableHandler from "@/modules/core/mixins/tableHandler";

  export default {
    name: "ListRecycler",
    components: {
      DataNotFound,
      Pagination,
      RecyclerMenuItem,
      ReportOptions
    },
    mixins: [MasksMixin, PermissionMixin, TableHandler],
    data: () => ({
      recyclers: [],
      headerReport: [
        { header: "Última destinação", dataKey: "lastDestination" },
        { header: "Empresa", dataKey: "name" },
        { header: "Cidade", dataKey: "city" },
        { header: "UF", dataKey: "uf" },
        { header: "Qtd. destinações", dataKey: "amountOfDestinations" }
      ],
      headers: [
        {
          text: "Última destinação",
          align: "start",
          value: "lastDestination"
        },
        {
          text: "Empresa",
          align: "start",
          value: "name"
        },
        {
          text: "Cidade",
          align: "start",
          value: "city"
        },
        {
          text: "UF",
          align: "start",
          value: "uf"
        },
        {
          text: "Qtd. destinações",
          align: "start",
          value: "amountOfDestinations"
        },
        { value: "id", sortable: false }
      ]
    }),
    computed: {
      canRegisterDonate() {
        return this.userHasRoutePermission("destination-reconditioning");
      },
      messageEmptyState() {
        return this.canRegisterDonate
          ? "Você ainda não cadastrou nenhuma destinação, deseja cadastrar?"
          : "Nenhuma destinação cadastrada.";
      }
    },
    async mounted() {
      await this.fetchRecyclers();
    },
    methods: {
      ...mapActions(["toggleSnackbar"]),
      ...mapActions("stock/destination", ["getRecyclers"]),
      async fetchRecyclers() {
        try {
          const filters = this.getPayloadData();
          const { data, meta } = await this.getRecyclers(filters);
          this.recyclers = this.formatData(data);
          this.setTableInfo(meta);
        } catch (error) {
          this.toggleSnackbar({
            text: error?.data?.message ?? "Ocorreu um erro. Tente novamente",
            type: "error"
          });
        }
      },
      updateData() {
        this.fetchRecyclers();
      },
      formatData(data) {
        return data?.map(recycler => ({
          ...recycler,
          lastDestination: this.parseDate(
            recycler.lastDestination,
            "dd/MM/yyyy - HH:mm"
          )
        }));
      },
      goToAddDonate() {
        this.$router.push({
          name: "destination-residues"
        });
      },
      showFilters() {
        return (
          this.recyclers.length ||
          (!this.recyclers.length && !this.filterPristine)
        );
      }
    }
  };
</script>

<style lang="scss" scoped></style>
