<template>
  <v-menu transition="slide-x-transition" bottom right>
    <template v-slot:activator="{ on, attrs }">
      <v-icon v-bind="attrs" v-on="on">
        mdi-dots-horizontal
      </v-icon>
    </template>
    <v-list>
      <v-list-item
        @click="goToDetail"
        class="d-flex align-center item-dropdown-user"
      >
        <v-list-item-title>
          <v-icon class="item-dropdown-icon">mdi-account</v-icon
          ><span class="pl-2">Ver perfil</span>
        </v-list-item-title>
      </v-list-item>
    </v-list>
  </v-menu>
</template>
<script>
  import { granteeTypes } from "../../enums/granteeTypes.enum";

  export default {
    name: "GranteeMenuItem",
    props: {
      id: {
        type: Number,
        required: true
      },
      personType: {
        type: String,
        required: true,
        validator: value => {
          return [granteeTypes.PF, granteeTypes.PJ].includes(value);
        }
      }
    },
    methods: {
      goToDetail() {
        const routeName =
          this.personType === granteeTypes.PF
            ? "detail-grantee-pf"
            : "detail-grantee-pj";

        this.$router.push({
          name: routeName,
          params: { id: this.id }
        });
      }
    }
  };
</script>

<style lang="scss" scoped></style>
