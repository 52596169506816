<template>
  <v-card class="pt-7">
    <div class="table-page d-flex flex-column justify-space-between">
      <div>
        <template v-if="showFilters">
          <div class="row px-5 d-flex justify-end">
            <v-col cols="9" sm="8" md="3">
              <v-text-field
                @input="setFilterDebounced('search', $event)"
                solo
                flat
                full-width
                color="secondary-color-1"
                background-color="white-1"
                placeholder="Buscar (Ex: nome / empresa)"
                prepend-inner-icon="mdi-magnify"
              />
            </v-col>
            <v-col cols="3" sm="4" md="2">
              <report-options
                fileName="Doações"
                :header="headerReport"
                :body="grantees"
              />
            </v-col>
          </div>
          <v-divider />
        </template>
        <template v-if="!grantees.length">
          <div class="pt-16">
            <data-not-found
              v-if="!filterPristine"
              title="Donatário não encontrado."
              :imgSrc="require('@/assets/user.svg')"
            />
            <data-not-found
              v-if="filterPristine"
              btnLabel="Cadastrar destinação"
              :title="messageEmptyState"
              :imgSrc="require('@/assets/partner.png')"
              :btnAction="goToAddDonate"
              :canSeeAction="canRegisterDonate"
            />
          </div>
        </template>
        <template v-if="grantees.length">
          <v-data-table
            :options.sync="dataTableOptions"
            :headers="headers"
            :items="grantees"
            :server-items-length="tableInfo.totalItems"
            hide-default-footer
          >
            <template v-slot:item.id="{ item }">
              <grantee-menu-item
                nameRoute="detail-grantee"
                :id="item.id"
                :personType="item.personType"
              />
            </template>
          </v-data-table>
        </template>
      </div>
      <div v-if="grantees.length">
        <pagination
          :currentPage="tableInfo.currentPage"
          :totalPages="tableInfo.totalPages"
          :itemsPerPage="tableInfo.itemsPerPage"
          @currentPageChanged="handleCurrentPageChange($event)"
          @itemsPerPageChanged="handleItemsPerPageChange($event)"
        />
      </div>
    </div>
  </v-card>
</template>

<script>
  import { mapActions } from "vuex";

  import DataNotFound from "@/modules/core/views/components/DataNotFound";
  import GranteeMenuItem from "@/modules/stock/destination/views/components/GranteeMenuItem";
  import Pagination from "@/modules/core/views/components/Pagination";
  import ReportOptions from "@/modules/core/views/components/ReportOptions";

  import MasksMixin from "@/modules/core/mixins/masksMixin";
  import PermissionMixin from "@/modules/core/mixins/permissionMixin";
  import TableHandler from "@/modules/core/mixins/tableHandler";

  export default {
    name: "ListGrantee",
    components: {
      DataNotFound,
      GranteeMenuItem,
      Pagination,
      ReportOptions
    },
    mixins: [MasksMixin, PermissionMixin, TableHandler],
    data: () => ({
      grantees: [],
      headerReport: [
        { header: "Última doação", dataKey: "lastDonation" },
        { header: "Nome /  Empresa", dataKey: "sourceName" },
        { header: "Cidade", dataKey: "city" },
        { header: "UF", dataKey: "uf" },
        { header: "Qtd. doações", dataKey: "amountOfDonations" }
      ],
      headers: [
        {
          text: "Última doação",
          align: "start",
          value: "lastDonation"
        },
        {
          text: "Nome / Empresa",
          align: "start",
          value: "sourceName"
        },
        {
          text: "Cidade",
          align: "start",
          value: "city"
        },
        {
          text: "UF",
          align: "start",
          value: "uf"
        },
        {
          text: "Qtd. doações",
          align: "start",
          value: "amountOfDonations"
        },
        { value: "id", sortable: false }
      ]
    }),
    computed: {
      canRegisterDonate() {
        return this.userHasRoutePermission("destination-reconditioning");
      },
      messageEmptyState() {
        return this.canRegisterDonate
          ? "Você ainda não cadastrou nenhuma destinação, deseja cadastrar?"
          : "Nenhuma destinação cadastrada.";
      }
    },
    async mounted() {
      await this.fetchGrantees();
    },
    methods: {
      ...mapActions(["toggleSnackbar"]),
      ...mapActions("stock/destination", ["getGrantees"]),
      async fetchGrantees() {
        try {
          const filters = this.getPayloadData();
          const { data, meta } = await this.getGrantees(filters);
          this.grantees = this.formatData(data);
          this.setTableInfo(meta);
        } catch (error) {
          this.toggleSnackbar({
            text: error?.data?.message ?? "Ocorreu um erro. Tente novamente",
            type: "error"
          });
        }
      },
      updateData() {
        this.fetchGrantees();
      },
      formatData(data) {
        return data?.map(grantee => ({
          ...grantee,
          lastDonation: this.parseDate(
            grantee.lastDonation,
            "dd/MM/yyyy - HH:mm"
          )
        }));
      },
      goToAddDonate() {
        this.$router.push({
          name: "destination-reconditioning"
        });
      },
      showFilters() {
        return (
          this.grantees.length ||
          (!this.grantees.length && !this.filterPristine)
        );
      }
    }
  };
</script>

<style lang="scss" scoped></style>
